import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
const PayData = {
    SetTime:null,//定时器
};

/**vue2 */
// Vue.prototype.$PayData = PayData;


/**vue3 */
app.config.globalProperties.PayData = PayData;

app.use(router)
app.use(ElementPlus)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.mount('#app')

app.config.silent = true;
